<template>
  <v-card
    class="mx-auto"
    elevation="0"
    height="100%"
    color="reform_primary_light_2"
  >
    <v-card-title>
      <v-container fluid>
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="12" class="text-center">
            <v-img
              v-if="
                hospLogo !== undefined && hospLogo !== null && hospLogo !== ''
              "
              class="ml-auto mr-auto"
              contain
              :src="'https://www.drrefer.net/api/pic/' + hospLogo"
              width="200px"
              height="100px"
            >
            </v-img>
            <v-icon size="40" v-else color="red">mdi-hospital-box</v-icon>
          </v-col>
          <v-col cols="12" class="text-center text-h6 font-weight-bold mt-3">
            {{ title }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-list nav rounded color="reform_primary_light_2">
      <v-list-item-group v-model="selectItem" mandatory>
        <v-list-item
          v-for="(item, j) in items"
          :key="j"
          active-class="active-list-menu"
          @click="goURL(item.to)"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="pl-10"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    selectItem: 0,
    items: [
      {
        title: "소개디자인 구매",
        to: "/v/payment/designList",
      },
      {
        title: "소개디자인 구매 관리",
        to: "/v/payment/refund",
      },
      {
        title: "소개디자인 신청서 관리",
        to: "/v/payment/manageApply",
      },
      {
        title: "쿠폰 등록",
        to: "/v/payment/registCoupon",
      },
    ],
    title: "",
    logo: "",
  }),
  created() {
    this.loadHospNm();
    this.setStatus();
  },
  computed: {
    ...mapGetters("join", ["GET_HOSP_NM", "GET_HOSP_LOGO"]),
    ...mapState("join", ["hospNm", "hospLogo"]),
  },
  methods: {
    ...mapActions("join", ["SELECT_HOSP_NM"]),
    setStatus() {
      let text = this.$route.path;
      if (text.includes("payment/designList")) {
        this.selectItem = 0;
      } else if (text.includes("payment/refund")) {
        this.selectItem = 1;
      } else if (text.includes("payment/manageApply")) {
        this.selectItem = 2;
      } else if (text.includes("payment/registCoupon")) {
        this.selectItem = 3;
      }
    },
    sendEvent(item) {
      if (
        this.selectItem === undefined ||
        this.selectItem === null ||
        this.selectItem === ""
      ) {
        return;
      }
      if (item.title === "진료 의뢰 현황") {
        this.$router
          .push("/v/mypage/request/" + this.selectItem)
          .catch(() => {});
      } else if (item.title === "쪽지 관리") {
        if (this.selectItem === 2) {
          const sendAppheaderItem = {
            method: "writeMessage",
            item: "noteList",
          };
          this.$emit("send-app-header-method", sendAppheaderItem);
        } else if (this.selectItem === 0) {
          this.$router.push("/v/mypage/sentnoteList").catch(() => {});
        } else if (this.selectItem === 1) {
          this.$router.push("/v/mypage/inboxnoteList").catch(() => {});
        }
      } else if (item.title === "회원 정보") {
        if (this.selectItem === 0) {
          this.$router.push("/v/mypage/modifymember").catch(() => {});
        } else if (this.selectItem === 1) {
          this.$router.push("/v/mypage/modifySearch").catch(() => {});
        } else if (this.selectItem === 2) {
          this.$router.push("/v/mypage/prmanage").catch(() => {});
        } else if (this.selectItem === 3) {
          this.$router.push("/v/mypage/appointmentList").catch(() => {});
        } else if (this.selectItem === 4) {
          this.$router.push("/v/mypage/changePw").catch(() => {});
        } else if (this.selectItem === 5) {
          this.$router.push("/v/mypage/sub").catch(() => {});
        } else if (this.selectItem === 6) {
          this.$router.push("/v/mypage/draw").catch(() => {});
        }
      }
    },
    goURL(url) {
      if (url === "") {
        const sendAppheaderItem = {
          method: "writeMessage",
          item: "noteList",
        };
        this.$emit("send-app-header-method", sendAppheaderItem);
      } else {
        this.$router.push(url).catch(() => {});
      }
    },
    async loadHospNm() {
      try {
        await this.SELECT_HOSP_NM().then(() => {
          this.title = this.hospNm;
          this.logo = this.hospLogo;
        });
      } catch {}
    },
  },
};
</script>

<style>
.active-list-menu {
  background-color: white;
}

.active-list-menu.theme--light.v-list-item--active::before {
  opacity: 0;
}
</style>
