<template>
  <v-container fluid class="ma-0 pa-0 mypage_container">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" xl="2" lg="2">
        <payment-sidebar
          ref="sidebar"
          @send-app-header-method="sendAppheaderMethod"
        />
      </v-col>
      <v-col cols="12" xl="10" lg="10">
        <router-view
          ref="requestList"
          @send-app-header-method="sendAppheaderMethod"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaymentSidebar from "../components/payment/PaymentSidebar.vue";
export default {
  components: {
    PaymentSidebar,
  },
  data() {
    return {
      selectItem: 0,
      title: 0,
    };
  },
  created() {},
  methods: {
    sendAppheaderMethod(sendAppheaderItem) {
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    sidebarUpdateMethod() {
      this.$refs.sidebar.loadHospNm();
    },
  },
};
</script>

<style>
.mypage_container {
  border-top: 1px solid gainsboro;
}
</style>
